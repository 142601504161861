import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useExternalScript } from "./useExternalScript";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { actionCreators } from "../../store/NavItems";

import clsx from "clsx";
import {
    Grid,
    Paper,
    Typography,
    CircularProgress,
} from "@material-ui/core";

import GoBackRecharge from "./GoBackRecharge";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // flexGrow: 1,
      paddingTop: theme.spacing(8),
      [theme.breakpoints.down("xs")]: {            
        paddingTop: theme.spacing(1),
      },
      
    },
    paper: {
      padding: theme.spacing(3, 1),
      boxShadow:
        "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    },
    paymentbox: {
        minHeight: 355,
        borderRadius: "1rem",
    },
    header: {
      fontWeight: 300,
      marginBottom: theme.spacing(2),
    },
    liqpay:{
        // padding: theme.spacing(2),
        // border: `1px solid ${theme.palette.divider}`,        
        // borderRadius: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {            
            margin: theme.spacing( 0, -1, 0 -1),
          },
    },
  })
);

const Liqpay = (props) => {
    const classes = useStyles();
    const auth = useContext(AppContext);
    const [token, setToken] = useState();
    const history = useHistory();
    const { payment } = props.location.state;

    const dispatch = useDispatch();     
    // const appLayout = useSelector((state) =>  state.appLayout );
    
    
    const status = useExternalScript("//static.liqpay.ua/libjs/checkout.js");

    // Get token
    useEffect(() => {
        if (!auth) return;

        function fetchUser() {
        auth.getUser().then(async (user) => {
            let token = null;
            if (user != null) {
            token = `Bearer ${user.access_token}`;
            }
            setToken(token);
        });
        }
        fetchUser();
    }, [auth]);

    // Liqpay checkot
    useEffect(() => {
        if(!token) return;
        if(!payment) return;

        //console.log('PAYMENT', payment);
        if(!window.LiqPayCheckout) return;

        const url = payment.docId ? 
                    `/api/v1.0/liqpay/payorder/${payment.docId}` : 
                    `/api/v1.0/liqpay/recharge?amount=${payment.amount}`;
        fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: token,
          },
        })
        .then((response) => response.json())
            .then((data) => {
                if (data) {
                    
                    if(process.env.NODE_ENV === "development")
                        console.log("Payment data", data);                        
                    window.LiqPayCheckout.init({
                        data: data.Data,
                        signature: data.Signature,
                        embedTo: "#liqpay_checkout",
                        mode: "embed",
                        language: data.Language,
                }).on("liqpay.callback", function (data) {
                    if(process.env.NODE_ENV === "development")                                                        
                        console.log("liqpay.callback data", data);

                    if(data){                        
                        if( data.result === "ok" && (data.status === "success" ||  data.status === "sandbox") && data.dae){
                            setTimeout(() => {
                                dispatch(actionCreators.getCustomer(token));
                            }, 2000);

                            const daeJSON = atob(data.dae);                        
                            const dae = JSON.parse(daeJSON);                                                        
                            if(dae && dae.baseId){                                
                                history.push(`/orderfin/reg/pp/${dae.baseId}`); 
                            }
                        }                        
                }
            }).on("liqpay.ready", function (data) {
                console.log("!!! LIQPAY READY", data); 
                self.setState({progress: false}); 
                if(width === "xs")                                                                                  
                    window.scrollTo({top: 299, behavior: "instant"});
                else
                    window.scrollTo({top: 170, behavior: "smooth"});

            }).on("liqpay.close", function (data) {                    
                console.log("liqpay.close", data);
            });

              
            }
          })
          .catch((error) => {
            console.log("Error", error);
          })
          .finally(() => {});
      }, [dispatch, history, payment, status, token]);

  return (
        <AppConsumer>
        {() => (
            <>
            <Helmet>
                {/* <script src="/payments/liqpay/checkout.js" async></script> */}
                {/* <script src="//static.liqpay.ua/libjs/checkout.js" async></script> */}
            </Helmet>
            <div className={classes.root}>                
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                >
                    <Grid item xs={12} sm={11} md={7}>
                        <GoBackRecharge />
                        <Paper className={ clsx(classes.paper, classes.paymentbox)} elevation={0}>
                        {!window.LiqPayCheckout && 
                            <Typography component='div' display="block" align="center" style={{paddingTop: 64}}>
                            <CircularProgress size={80} />
                            </Typography>                
                        }
                        
                        <div className={classes.liqpay} id="liqpay_checkout"></div>                            
                        </Paper>
                    </Grid>

                </Grid>                
            </div>
            </>
        )}
        </AppConsumer>
    );  
};
export default connect(
    state => state.appLayout,
    dispatch => bindActionCreators(actionCreators, dispatch)
  )(Liqpay)
  
// export default Liqpay