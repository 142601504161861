import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useExternalScript } from "./useExternalScript";

const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        // paddingTop: theme.spacing(8),
        // [theme.breakpoints.down("xs")]: {            
        //   paddingTop: theme.spacing(1),
        // },
        
      },
      paper: {
        padding: theme.spacing(3, 1),
        boxShadow:
          "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
      },
      paymentbox: {
          minHeight: 355,
          borderRadius: "1rem",
      },
      header: {
        fontWeight: 300,
        marginBottom: theme.spacing(2),
      },
      liqpay:{
          // padding: theme.spacing(2),
          // border: `1px solid ${theme.palette.divider}`,        
          // borderRadius: theme.spacing(4),
          [theme.breakpoints.down("xs")]: {            
              margin: theme.spacing( 0, -1, 0 -1),
            },
      },
    })
  );

const LyqpayComponent = props => {
    const { amount, regnumber } = props;
    const classes = useStyles();
    const status = useExternalScript("//static.liqpay.ua/libjs/checkout.js");
    
    const callTransfer = useCallback(async () => {
        if(!window.LiqPayCheckout){
            return;
        }
        const url = "/api/v1.0/liqpay/transfer";
        const resTransfer = await fetch(url, {
            method: "POST", 
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"                
              },
            body: JSON.stringify({amount: amount, regnumber: regnumber })
        });

        if (resTransfer.status !== 200) {
            return;
        }
        const data = await resTransfer.json();
        // console.log(data);
        window.LiqPayCheckout.init({
            data: data.Data,
            signature: data.Signature,
            embedTo: "#liqpay_checkout",
            mode: "embed", // embed || popup
            language: data.Language,
            paytypes: data.Paytypes,
        }).on("liqpay.callback", function (data) {                                                            
            // console.log("liqpay.callback data", data);
            if(data){
                const formData = new FormData();
                formData.append("data", data.data);
                formData.append("signature", data.signature);
                
                fetch("/api/v1.0/liqpay/callback", 
                {
                    method: "POST", 
                    body: formData, 
                    // "Content-Type": "multipart/form-data"
                }
                )                                
                .then(function(result){
                    console.log("liqpay.callback RESULT", result);
                    // if(result.status === 200){
                    //     self.setState({showSuccess: true });
                        
                    //     self.successRecharge();
                    // }                        
                })
                .catch((error) => {
                    // Error 😨
                    if (error.response) {
                        /*
                        * The request was made and the server responded with a
                        * status code that falls out of the range of 2xx
                        */
                        console.log("error.response.data", error.response.data);
                        console.log("error.response.status", error.response.status);
                        console.log("error.response.headers", error.response.headers);
                    } else if (error.request) {
                        /*
                        * The request was made but no response was received, `error.request`
                        * is an instance of XMLHttpRequest in the browser and an instance
                        * of http.ClientRequest in Node.js
                        */
                        console.log("error.request", error.request);
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log("error.message", error.message);
                    }
                    console.log("error.config",error.config);
                });
            }
        }).on("liqpay.ready", function (data) {
            // console.log("!!! LIQPAY READY", data);                             
            // if(fullScreen === "xs")                                                                                  
            //     window.scrollTo({top: 299, behavior: "instant"});
            // else
            //     window.scrollTo({top: 170, behavior: "smooth"});

        }).on("liqpay.close", function (data) {                    
            console.log("liqpay.close", data);
        });
    }, [amount, regnumber]);


    useEffect( () => {
        callTransfer()        
    }, [status, amount, callTransfer]);

    return (
        <div className={classes.root}>            
        {!window.LiqPayCheckout && 
        <Typography component='div' display="block" align="center" style={{paddingTop: 64}}>
            <CircularProgress size={80} />
        </Typography>
        }
            <div className={classes.liqpay} id="liqpay_checkout"></div> 
        </div>
    )
}

LyqpayComponent.propTypes = {
    regnumber: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    username: PropTypes.string,
}

export default LyqpayComponent;