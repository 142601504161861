import React, { useState, useContext, useEffect, useCallback } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {    
    Paper,
    Button,    
    Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
createStyles({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(4, 2),
        borderRadius: theme.spacing(2),
        boxShadow: theme.palette.ambilight.boxshadow,                                    
    }
}));


const ChatGpt = () => {
    const classes = useStyles();

  return (
    <div className={classes.root}>
        <Paper className={clsx(classes.paper, "chat-gpt")}>
            <Typography variant="h5" gutterBottom>ChatGpt</Typography>

            
        </Paper>        
    </div>    
  )
}

export default ChatGpt;