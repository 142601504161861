
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { actionRegister } from "../../store/Register";
import { Avatar, IconButton, Button, Paper, 
         Grid, Typography, FormControl, TextField, Checkbox, Link, InputLabel, Select, InputAdornment, FormControlLabel} from "@material-ui/core";
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

import deepPurple from "@material-ui/core/colors/deepPurple";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import lightBlue  from "@material-ui/core/colors/lightBlue";
import red  from "@material-ui/core/colors/red";

import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { CustomerState } from "../../definitions/customer";
import { TelegramIcon, ViberIcon } from "../icons/jerelia-icons";
import SearchIcon from "@material-ui/icons/Search";
import { countries, phones, onlyCountries } from "../../store/countries";
// import ErrorIcon from "@material-ui/icons/Error";
// import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";


// eslint-disable-next-line no-undef
const logo = process.env.REACT_APP_LOGO;
// eslint-disable-next-line no-undef
const host = process.env.NODE_ENV === "development"? "https://jerelia.com": "";
const maxLength = 7;
const minPasswordLenth = 6;

const getUserName = (userName) => {
  if(!userName){
    return "";
  }
  const words = userName.split(" ");
  const res = (`${words[1].charAt(0)}${words[0].charAt(0)}`).toUpperCase();
  return res;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {        
      padding: theme.spacing(2,8),
    },
    
  },
  content: {
    background: "linear-gradient(0.00deg, #ffffff 97.00%, #54d1e621 99.00%)"    
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  logo: {
    paddingTop: theme.spacing(1),
    width: 152,
    height: "auto",
    [theme.breakpoints.down("xs")]: {  
      paddingTop: 4,
      width: 96,
    },
  },
  label: {
    padding: "0 16px 0 8px",
    backgroundColor: theme.palette.type === "light" ? "white" : theme.palette.grey[800],
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  btnViber: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    "&:hover": {
      backgroundColor: deepPurple[400],
    },
  },
  btnTelegram: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
    "&:hover": {
      backgroundColor: lightBlue[500],
    },
  },
  paper: {
    padding: theme.spacing(3,2),
    textAlign: "center",    
    minHeight: theme.spacing(8),
    borderRadius: theme.spacing(2),
    boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  },
  paperHeader: {
    padding: theme.spacing(1),
    textAlign: "center",        
  },
  btn: {
    borderRadius: theme.spacing(3)
  },
  
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    // border: "3px solid #48bfde",
    fontSize: "3em",
    lineHeight: "3em",
    color: theme.palette.getContrastText(lightBlue[700]),
    backgroundColor: theme.palette.primary.main,
    letterSpacing: "-0.1em",
    // [theme.breakpoints.down("xs")]: {        
    //     display: "none",           
    // },
  }, 
  biggest: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    // border: "3px solid #48bfde",
    fontWeight: 300,
    fontSize: "4em",    
    lineHeight: "4em",
    letterSpacing: "-0.05em",
    color: theme.palette.getContrastText(lightBlue[700]),
    backgroundColor: theme.palette.primary.main,
    
    // [theme.breakpoints.down("xs")]: {        
    //     display: "none",           
    // },
  },
  errorAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),        
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],        
  },

  selmentor: {
    textTransform: "capitalize",
  },

  mentor: {
    textTransform: "capitalize",
    fontSize: ".8rem",
    fontWeight: 500
  },

  searchMentor: {
    textTransform: "capitalize",
  },
  mentorDetails: {
    textTransform: "capitalize",    
    fontWeight: 400
  },
  annonce: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.5em",
    height: "6em",
    width: "100%", 
    "& > p": {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      padding: 0,
      margin: 0,
    },
  },
  btnDetails: {
    fontSize: ".87em"
  },
  fullDescr: {
    padding: theme.spacing(2),
    textAlign: "justify",
    "& > p": {      
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
  phone: {
    width: "100%",    
  },
  error: {
    paddingTop: 4,
    marginLeft: 14,
    marginRight: 14,
    fontSize: "0.75rem",
    color: "#f44336"
  },
  buttonProgress: {    
    position: "absolute",    
    // top: "50%",
    left: "50%",
    marginTop: 8,
    marginLeft: -12,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const removeRecaptcha = () => {
    const script = document.getElementById('recaptcha-script');
    if (script) {
        script.remove();
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
        recaptchaElems[0].remove();
    }
}


class RegisterClient extends Component {
  constructor(props) {
    super(props);
    this.state = {    
      showDlgSelectAdviser: false,
      showDlgBestAdviser: false,
      showDlgDetailsAdviser: false,
      showDlgSearchAdviser: false,
      detailsAdviser: null,
      firstnameErrorTxt: "",
      lastnameErrorTxt: "",
      phonenumberErrorTxt: "",
      emailErrorTxt: "",
      passwordErrorTxt: "",
      mentorErrorTxt: "",
      remoteValidate: false,
      ref: null,
      token: undefined,
      submitEnabled: false
    };
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  getRefId = () => {
    const query = new URLSearchParams(window.location.search.toLowerCase());
    let ref = this.props.match.params.ref || "";
    if( ref === undefined || ref.length === 0){
        const fromquery = query.getAll("ref");
        if(fromquery.length !== 0){
            ref = fromquery.toString();
        }
        
        if(ref.length === 0){
            // const { cookies } = this.props;
            // ref = cookies.get("ref");
            ref = window.sessionStorage.getItem("ref");
        }
    }            
    if(ref){
      this.setState({ref: ref});
      this.props.requesMentor(ref);
    }
  };
  
  componentDidMount() {
    this.getRefId();
    this.props.requesMentors();
    this.props.requestCountries();
    this.props.changeCustomerState(CustomerState.AuthorizedUser);
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    window.scrollTo({top: 0, behavior: "smooth"});
  }
  
  componentDidUpdate(prevProps) {
    if(this.props.customerState !== prevProps.customerState ) {      
      this.props.changeCustomerState(CustomerState.AuthorizedUser);
    }
  }

  componentWillUnmount(){
    removeRecaptcha();
  }

  handleChange = (e) => {
    // if( e.target.name === "regNumber" && e.target.value.length === maxLength){
    //   console.log("Search adviser by reg number", e.target.value.length);
    // }    
    
    this.props.changeField(e);
    
    if( e.target.name === "FirstName" && e.target.value.length > 0){
      this.setState({ firstnameError: false, firstnameErrorTxt: ""});
    } else if( e.target.name === "LastName" && e.target.value.length > 0){
      this.setState({ lastnameError: false, lastnameErrorTxt: ""});
    } else if( e.target.name === "Email" && e.target.value.length > 0){
      this.setState({ emailError: false, emailErrorTxt: ""});
    } else if( e.target.name === "Password" && e.target.value.length > 0){
      this.setState({ passwordError: false, passwordErrorTxt: ""});
    } else if( e.target.name === "regNumber" && e.target.value.length > 0){
      this.setState({ mentorError: false, mentorErrorTxt: ""});
    }
    
  };

  handleSearchMentor = () => {
    const {regNumber} = this.props;
    if(regNumber.length === maxLength){
      // console.log("Search adviser by reg number", regNumber);
      this.setState({showDlgSearchAdviser: true});
      this.props.findMentor(regNumber);
    }
  }

  handeleKeyPress = (e) => {
    if(e.keyCode === 13) {
      this.handleSearchMentor();
    }
  };

  handleChangePhone = (phone) => {
    this.props.changePhoneNumber(phone);
    if( phone.length > 0){
      this.setState({ phonenumberError: false, phonenumberErrorTxt: ""});
    }
  };
  
  handleShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({showPassword: !showPassword});
  };

  handleChangeAgree = (e) => {
    this.props.changeCheckBox(e);    
  };

  handleCloseDlgSelectAdviser = () => {
    this.setState({showDlgSelectAdviser: false});
  };

  handleCloseDlgBestAdviser = () => {
    this.setState({showDlgBestAdviser: false});
  };

  handleCloseDlgDetailsAdviser = () => {
    this.setState({showDlgDetailsAdviser: false});
  };

  handleCloseDlgSearchAdviser = () => {
    this.setState({showDlgSearchAdviser: false});
  };

  handleSelectSearchAdviser = () => {
    this.setState({showDlgSearchAdviser: false, showDlgBestAdviser: false, mentorError: false, mentorErrorTxt: "" });
    this.props.setMentor();
  };
  
  handleCancelDlgSearchAdviser = () => {
    // this.props.clearSelectedMentor();
    this.setState({showDlgSearchAdviser: false});
  };

  handleGetBestAdviser = () => {
    this.setState({showDlgSelectAdviser: false, showDlgBestAdviser: true});
  };

  handleSelectAdviser = (regNumber) => {
    this.props.requesMentor(regNumber);
    this.setState({ showDlgBestAdviser: false, showDlgDetailsAdviser: false, mentorError: false, mentorErrorTxt: "" });
    window.scrollTo({top: 0, behavior: "smooth"});
  };
  
  handleDetailsAdviser = (regNumber) => {
    const {mentors} = this.props;
    const mentor = mentors.find(el => el.RegistrationCode === regNumber);
    console.log("Details", mentor);
    if(mentor){
      this.setState({detailsAdviser: mentor, showDlgDetailsAdviser: true });
      // alert(mentor.UserName);
    }        
  };

  validate = () => {
    const { FirstName, LastName, PhoneNumber, Email, Password, regNumber, mentor, t } = this.props;
    let errors = false;
    if(FirstName.length === 0 ){
      this.setState({ firstnameError: true, firstnameErrorTxt: t("register.EmptyFirstName")});
      errors = true;
    }

    if(LastName.length === 0 ){
      this.setState({ lastnameError: true, lastnameErrorTxt: t("register.EmptyLastName")});
      errors = true;
    }

    if(PhoneNumber.length <= 3){
      this.setState({ phonenumberError: true, phonenumberErrorTxt: t("register.EmptyPhone")});
      errors = true;
    }

    // if(PhoneNumber.length === 0 ){
    //   this.setState({ phonenumberError: true, phonenumberErrorTxt: t("register.EmptyPhone")});
    //   errors = true;
    // } else {
    //   if(PhoneNumber.length !== 12 ){        
    //     this.setState({ phonenumberError: true, phonenumberErrorTxt: t("register.BadPhoneFormat")});
    //     errors = true;
    //   }
    // }

    if(Email.length === 0 ){
      this.setState({ emailError: true, emailErrorTxt: t("register.EmptyEmail")});
      errors = true;
    }else{
      const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(Email)) {
        this.setState({ emailError: true, emailErrorTxt: t("register.BadEmailFormat")});
        errors = true;
      }
    }

    if(Password.length < minPasswordLenth ){
      this.setState({ passwordError: true, passwordErrorTxt: 
        t("register.BadPasswordLenth", {length: minPasswordLenth})} );
        // `Password length must be at least ${minPasswordLenth}`});
      errors = true;
    }

    if(!mentor){
      if(regNumber.length === 0){
        this.setState({ mentorError: true, mentorErrorTxt: t("register.EmptyRegNumber")});
        errors = true;
      }
    }else{
      this.setState({ mentorError: false, mentorErrorTxt: ""});
    }

    return !errors;
  };

  handleNextRegister = () => {
    const { Email, PhoneNumber, regNumber, mentor, t } = this.props;        
    const valid = this.validate();
    
    if(!valid){ 
      this.setState({remoteValidate: false});
      window.scrollTo({top: 0, behavior: "smooth"});   
      return;
    }
      
    this.setState({remoteValidate: true});
    const self = this;
    window.grecaptcha.ready(function() {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'homepage'}).then(function(token) {
            console.log(token);
            const url = '/api/v1.0/Recaptcha/Siteverify';
            // const url = `https://www.google.com/recaptcha/api/siteverify?secret=${REACT_APP_RECAPTCHA_SECRET}&response=${token}`;
            fetch(url, { 
                method: "POST", 
                body: JSON.stringify({ "token": token }),                
                headers: {                  
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8"
                },
            })
                .then(response => response.json())
                .then(data => {                    
                    if (data) {
                        console.log('Recaptcha/Siteverify', data );
                        if(data.success && data.score > 0.7){    

                            const url = `/api/v1.0/Adviser/ValidateUserByEmailPhone/${Email}/${PhoneNumber}/${(mentor ? mentor.RegistrationCode : regNumber)}`;
            
                            fetch(url)
                                .then(response => response.json())
                                .then(data => {
                                    console.log("GET VALIDATE DATA", data);
                                    if(!data.Mentor){
                                        self.props.clearSelectedMentor();
                                        self.setState({ mentorError: true, mentorErrorTxt: t("register.NotMentorSelected")});
                                    }else{
                                        self.props.setMentor(data.Mentor);
                                    }
                                            
                                    if(data.emailError){
                                        self.setState({ emailError: true, emailErrorTxt: data.emailErrorTxt});
                                    }
                                    
                                    if(data.phonenumberError){
                                        self.setState({ phonenumberError: true, phonenumberErrorTxt: data.phonenumberErrorTxt});
                                    }

                                    if(data.emailError || data.phonenumberError || !data.Mentor ) {
                                        self.setState({remoteValidate: false});
                                        return;
                                    }            

                                    self.props.history.push("/register_client_success");
                                    
                                    })
                                    .catch((error) => {
                                        self.setState({remoteValidate: true});
                                        console.log(error)
                                    });
                        }
                    }
                        })
                    .catch(error => {                
                        console.log("Error", error);
                    });
            }); 
        });
        return;       
  };

    verifyCallback = (token) => {
        // Here you will get the final recaptchaToken!!!  
        // console.log(recaptchaToken, "<= your recaptcha token");
        this.setState({ token: token });    
    }

    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute();
    }

  render(){    
    // eslint-disable-next-line no-unused-vars
    const { classes, mentor, finded, mentors, t,
            Agree, PromoCode, LastName, FirstName, Password, PhoneNumber, Email, Country, allowCountries,
            regNumber, isLoading
          } = this.props;
    
    const { showPassword, detailsAdviser, showDlgSelectAdviser, showDlgBestAdviser, 
            showDlgDetailsAdviser, showDlgSearchAdviser, ref, phonenumberError } = this.state;

    const shortName = mentor ?  getUserName(mentor.Name) : "";    

    return (
      <>
      {isLoading && 
        <LinearProgress />
      }

      <div className={classes.root}>

        <Paper className={classes.paper}>
            <ReCaptcha            
                sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}
                action='main'
                verifyCallback={this.verifyCallback}
            />

        { mentor && 
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} style={{marginBottom: 16}}>
          <Grid item>
          {
            mentor.Avatar && mentor.Avatar.length > 0 ?
            <Avatar alt={shortName} src={`${host}${mentor.Avatar}`} className={classes.large} /> : 
            <Avatar className={classes.large}>
                { shortName }
            </Avatar>
          }
          </Grid>
          <Grid item >
            <Typography variant="subtitle2">{t("register.YourConsultant")}:</Typography>
            <Typography className={classes.selmentor} gutterBottom>{mentor.FirstName.toLowerCase()} {mentor.LastName.toLowerCase()}</Typography>
            <Typography variant="body2">{t("register.ConsultantID")}:</Typography>
            <Typography variant="h6">{mentor.RegistrationCode}</Typography>
          </Grid>
        </Grid> 
        }

        { (this.state.firstnameError || this.state.lastnameError || this.state.phonenumberError || 
         this.state.emailError ||  this.state.passwordError || this.state.mentorError) &&  
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginBottom: 16}}>            
            <Grid item>
              <Alert severity="error">{t("register.CommonError")}</Alert>              
            </Grid> 
          </Grid>
          }        
        <Typography variant="h5" align="center"> {t("register.titleClient")}</Typography> 
        <Typography variant="subtitle2" color="primary" gutterBottom align="center"> {t("register.subtitleClient")}</Typography> 

        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={12} sm={6} md={4} >
            <TextField                    
              label={t("register.PromoCode")}
              type="text"
              name="PromoCode"
              variant="outlined"
              autoComplete="off"                                          
              fullWidth={true}
              margin="normal"
              value={PromoCode}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6} md={4} >
            <TextField                    
              label={t("register.FirstName")}
              type="text"
              name="FirstName"                        
              variant="outlined"
              autoComplete="off"
              required                                                                                         
              error={this.state.firstnameError}
              helperText={this.state.firstnameErrorTxt}
              fullWidth={true}
              margin="normal"
              value={FirstName}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
          <Grid item xs={12} sm={6} md={4} >
            <TextField                    
              label={t("register.LastName")}
              type="text"
              name="LastName"                        
              variant="outlined"
              required
              autoComplete="off"                                                                                 
              error={this.state.lastnameError}
              helperText={this.state.lastnameErrorTxt}
              fullWidth={true} 
              margin="normal"             
              value={LastName}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} style={{marginTop: 16, marginBottom: 8}}>
          <Grid item xs={12} sm={6} md={4} >             
            <FormControl fullWidth variant="outlined">
              <InputLabel className={classes.label} htmlFor="Country">{t("order.RecipientCountry")}</InputLabel>
              <Select                
                native
                value={Country}
                onChange={this.handleChange}
                inputProps={{
                  name: 'Country',
                  id: 'Country',
                }}
              >     
              { countries.map((contry) => (
                allowCountries.includes(contry.code) ? <option key={contry.code} value={contry.code}>{contry.name}</option>: null
                // <option key={contry.code} value={contry.code}>{contry.name}</option>
              ))}                                                  
              </Select>
            </FormControl>
          </Grid>
        </Grid>        
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginTop: 16, marginBottom: 8}}>
          <Grid item xs={12} sm={6} md={4} >
            <PhoneInput inputClass={classes.phone}
              name="PhoneNumber"
              specialLabel={t("register.PhoneNumber")}
              // onlyCountries={onlyCountries}
              inputProps={{                    
                name: "PhoneNumber",                
                required: true,                
                style: {width: "100%"}                      
              }}
              // isValid={!this.state.phonenumberError}
              isValid={(value, country) => {                
                if(phonenumberError)
                  return false;               
                if(value === country.countryCode){
                  return true;
                }else{
                  const pattern = new RegExp(phones[country.iso2.toUpperCase()]);
                  if (!pattern.test(value)) {
                    return `${t("register.BadPhoneFormat")}: ${value}  ${country.name}`;              
                  }else{
                    return true;
                  }
                }              
              }}
              country={"ua"}
              value={PhoneNumber}
              placeholder="+X.. XX XXX XX XX"                    
              onChange={this.handleChangePhone}
            />
            { this.state.phonenumberError && <Typography className={classes.error}>{this.state.phonenumberErrorTxt}</Typography>  }
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6} md={4} >
            <TextField                        
              label="Email"                        
              type="search"
              name="Email"
              InputProps={{ autoComplete: "off" }}
              variant="outlined"
              required                                                                                                
              error={this.state.emailError}
              helperText={this.state.emailErrorTxt}
              fullWidth={true}
              margin="normal"
              value={Email}
              onChange={e => this.handleChange(e)}
          />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6} md={4} >
            <TextField                            
              label={t("register.Password", {length: minPasswordLenth})}
              type={showPassword ? "text" : "password"}
              name="Password" 
              InputProps={{
                  autoComplete: "new-password",
                  endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleShowPassword}                          
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
              }}                                                           
              variant="outlined"
              required                                                                                                
              error={this.state.passwordError}
              helperText={this.state.passwordErrorTxt}
              fullWidth={true}
              margin="normal"
              value={Password}
              onChange={e => this.handleChange(e)}
          />
          </Grid>
        </Grid>
        
        {/* Search mentor */}
        {!mentor && 
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
          <Grid item xs={12} sm={6} md={4} >
            <TextField                    
              label={`${t("register.SearchPersonalConsultant")}` }
              type="text"
              name="regNumber"                        
              variant="outlined"
              required
              autoComplete="off"                                                                                 
              error={this.state.mentorError}
              helperText={ this.state.mentorError? this.state.mentorErrorTxt: t("register.MaxLenRegNumber") }
              fullWidth={true} 
              margin="normal"             
              value={regNumber}
              onChange={this.handleChange}
              onKeyDown={this.handeleKeyPress}
            />
          </Grid>
        </Grid>
        }

        <Grid container direction="row" justifyContent="center" alignItems="center" style={{marginTop: 8, marginBottom: 16}}>
          <Grid item xs={12} sm={3}>
            <Button size="large" 
              className={classes.btn}
              fullWidth disabled={!Agree || this.state.remoteValidate}
              variant="contained" 
              // endIcon={<ArrowForwardIcon />}
              onClick = {this.handleNextRegister}              
              color="primary">{t("register.btnRegister")}</Button>
               {this.state.remoteValidate && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Grid>
        </Grid>
        
        {!ref && 
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{marginTop: 8, marginBottom: 16}}>
          <Grid item xs={12} sm={3}>
            <Button 
              className={classes.btn}
              fullWidth 
              variant="outlined"
              onClick = {() => this.setState({showDlgBestAdviser: true, mentorError: false, mentorErrorTxt: ""}) }
              color="primary">{mentor ? t("register.btnChangeConsultant") : t("register.btnNotConsultant")}</Button>
          </Grid>
        </Grid>
        }

        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginTop: 8, marginBottom: 16}}>
          <Grid item xs={12} sm={6} md={4} >
            <FormControlLabel
              control={
                <Checkbox 
                  checked={Agree} 
                  onChange={this.handleChangeAgree} 
                  color="primary"
                  name="Agree" />
              }
              label={<Typography variant="body2">{t("register.ConsentProcessing")}</Typography>}
            />
            <Link target="_blank" href="/page/offer" variant="body2">{t("register.ReadConsent")}</Link>  
          </Grid>
        </Grid>        

        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <IconButton className={classes.btnViber} aria-label="viber" target="_about" href="viber://pa?chatURI=JereliaSupport">
              <ViberIcon fontSize="small" width={24} height={24} />
            </IconButton>
            <IconButton className={classes.btnTelegram} aria-label="telegram" target="_about" href="https://t.me/JR_support_Bot">
              <TelegramIcon fontSize="small" width={24} height={24} />
            </IconButton>
          </Grid>
          <Grid item xs={7} sm={4} md={3}>
            <Typography variant="body2" >{t("register.ContactSupport")}</Typography>
          </Grid>
        </Grid>

        {/* Dlg select adviser */}
        <Dialog 
          fullWidth={true}
          maxWidth={"xs"}
          onClose={this.handleCloseDlgSelectAdviser} 
          aria-labelledby="adviser-dialog" 
          open={showDlgSelectAdviser} >
        <DialogTitle id="adviser-dialog" onClose={this.handleCloseDlgSelectAdviser}>&nbsp;</DialogTitle>
        <DialogContent>
          <Typography variant="h6" align="center" gutterBottom>
            Хочу обрати кращого наставника
          </Typography>                              
        </DialogContent>
        <DialogActions>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginBottom: 8}}>
            <Grid item xs={5} sm={3}>
              <Button fullWidth className={classes.btn}
                variant="contained" color="primary"
                onClick={this.handleGetBestAdviser}
                >
                {t("buttons.Yes") }
              </Button>
            </Grid>
            <Grid item xs={5} sm={3}>
              <Button fullWidth 
                className={classes.btn}
                variant="outlined"              
                color="primary"
                onClick={this.handleCloseDlgSelectAdviser}
                >
                  {t("buttons.No") }
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

        {/* Dlg select best adviser */}
        <Dialog 
          fullScreen TransitionComponent={Transition}
          onClose={this.handleCloseDlgBestAdviser} 
          aria-labelledby="best-adviser-dialog" 
          open={showDlgBestAdviser} > 
          <AppBar className={classes.appBar} color="inherit" elevation={0}>
          <Toolbar>            
            <Typography align="center" className={classes.title}>
              <img className={classes.logo} alt="Jerelia" src={`assets/${logo}`} />
            </Typography>
            <IconButton edge="start" color="inherit" onClick={this.handleCloseDlgBestAdviser} aria-label="close">
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={this.handleCloseDlgBestAdviser}>
              Close
            </Button> */}
          </Toolbar>
        </AppBar>
        
        <DialogContent className={classes.content}>
          

        <Typography variant="h5" align="center" gutterBottom >
          {t("register.SelectOurConsultant")}
        </Typography>

        <Grid container direction="row" justifyContent="center" alignItems="center" >            
            <Grid item xs={11} sm={4} md={3} >
              <TextField                    
                label={`${t("register.SearchMentor")}` }
                type="text"
                name="regNumber"                        
                variant="outlined"
                required
                autoComplete="off"                                                                                 
                // error={this.state.mentorError}
                helperText={ this.state.mentorError? this.state.mentorErrorTxt: t("register.MaxLenRegNumber") }
                fullWidth={true} 
                margin="normal"             
                value={regNumber}
                onChange={this.handleChange}
                onKeyDown={this.handeleKeyPress}
                InputProps={{ 
                  maxLength: maxLength,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle search mentor"
                        onClick={this.handleSearchMentor}                      
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
                              
          {mentors && 
            <React.Fragment>
            <Grid container direction="row" spacing={2} >              
              { mentors.map( (director) => (
                <Grid item key={director.RegistrationCode} xs={6} sm={4} md={3} style={{marginBottom: 16}} >
                  <Paper className={classes.paper} elevation={1}>
                  <Grid container direction="row" justifyContent="center" alignItems="center" style={{marginBottom: 8}}>
                    <Grid item>
                      {
                        director.Avatar.length > 0 ?
                        <Avatar alt={shortName} src={`${host}${director.Avatar}`} className={classes.biggest} onClick={ ()=> this.handleDetailsAdviser(director.RegistrationCode) }  /> : 
                        <Avatar className={classes.biggest} onClick={ ()=> this.handleDetailsAdviser(director.RegistrationCode) }>
                            {getUserName(`${director.LastName} ${director.FirstName}`)}
                        </Avatar>
                      }
                    </Grid>
                  </Grid>

                  <Typography align="center" className={classes.mentor} variant="subtitle2" gutterBottom display="block">
                    {director.FirstName.toLowerCase()} {director.LastName.toLowerCase()},
                    <Typography className={classes.mentor} component="p" variant="subtitle2" color="textSecondary">{director.VillageCityTown.toLowerCase()}</Typography>
                  </Typography>
                  
                  <Typography component="div" className={classes.annonce} variant="caption" display="block" dangerouslySetInnerHTML={{ __html: director.Comment }} >                    
                  </Typography>
                  <Typography align="right" variant="caption" display="block" gutterBottom>
                    <Button className={classes.btnDetails} size="small" onClick={ ()=> this.handleDetailsAdviser(director.RegistrationCode) }  >
                      Детально...
                    </Button>
                  </Typography>
                  <Grid container direction="row" justifyContent="center" alignItems="center" style={{marginBottom: 8}}>
                    <Grid item>
                      <Button                          
                          color="primary"
                          onClick={()=> this.handleSelectAdviser(director.RegistrationCode)}
                          >
                            {t("buttons.Select") }
                      </Button>
                    </Grid>
                  </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            </React.Fragment>
          }
        </DialogContent>

        <DialogActions>                
          <Button 
            className={classes.btn}
            variant="outlined"              
            color="primary"
            onClick={this.handleCloseDlgBestAdviser}
            >
              {t("buttons.Cancel") }
          </Button>
        </DialogActions>
      </Dialog>
      
        {/* Dlg details adviser */}
        <Dialog 
          // fullScreen TransitionComponent={Transition}
          onClose={this.handleCloseDlgDetailsAdviser} 
          aria-labelledby="details-adviser-dialog" 
          open={showDlgDetailsAdviser} > 
          <AppBar className={classes.appBar} color="inherit" elevation={0}>
          <Toolbar>            
            <Typography align="center" className={classes.title}>
              <img className={classes.logo} alt="Jerelia" src={`assets/${logo}`} />
            </Typography>
            <IconButton edge="start" color="inherit" onClick={this.handleCloseDlgDetailsAdviser} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        
        <DialogContent className={classes.content}>          
          {detailsAdviser && 
            <div>
              <Grid container direction="row" justifyContent="center" alignItems="center"  spacing={2} style={{marginTop: 8, marginBottom: 8}} >
                <Grid item>
                  {
                    detailsAdviser.Avatar.length > 0 ?
                    <Avatar  alt={shortName} src={`${host}${detailsAdviser.Avatar}`} className={classes.biggest}  /> : 
                    <Avatar className={classes.biggest} >
                        {getUserName(`${detailsAdviser.FirstName} ${detailsAdviser.LastName}`)}
                    </Avatar>
                  }
                </Grid>
              </Grid>
              
              <Typography className={classes.mentorDetails} align="center" variant="h5" display="block">
                {detailsAdviser.FirstName.toLowerCase()} {detailsAdviser.LastName.toLowerCase()},
                <Typography component="p" variant="body1" color="textSecondary">{detailsAdviser.VillageCityTown.toLowerCase()}</Typography>
              </Typography>

              <Typography className={classes.fullDescr} variant="body2" display="block" gutterBottom dangerouslySetInnerHTML={{ __html: detailsAdviser.Comment }}>
                
              </Typography>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={6} sm={3}>
              <Button 
                  className={classes.btn} fullWidth 
                  variant="contained" color="primary"
                  onClick={()=> this.handleSelectAdviser(detailsAdviser.RegistrationCode)}
                  >
                    {t("buttons.Select") }
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        
      </Dialog>                          
        
        {/* Dlg search adviser */}
        <Dialog           
          disableEscapeKeyDown
          fullWidth={true}
          maxWidth={"xs"}
          onClose={this.handleCloseDlgSearchAdviser} 
          aria-labelledby="adviser-dialog" 
          open={showDlgSearchAdviser} >        
        <DialogContent>
          {isLoading &&
            <Typography component="div" align="center" gutterBottom>
              <CircularProgress />
            </Typography>
          }

          { finded && !isLoading &&
          <Grid container direction="row" justifyContent="center" alignItems="center" style={{marginBottom: 8}}>
            <Grid item>
            {
              finded.Avatar.length > 0 ?
                <Avatar  alt={shortName} src={`${host}${finded.Avatar}`} className={classes.biggest} /> : 
                <Avatar className={classes.biggest}>
                  {getUserName(`${finded.FirstName} ${finded.LastName}`)}
                </Avatar>
            }
            </Grid>
          
            <Grid item xs={12}>
              <Typography align="center" variant="body1" gutterBottom display="block" style={{marginTop: 16}}>
                {finded.RegistrationCode}
              </Typography>
              <Typography className={classes.searchMentor} align="center" variant="h6" gutterBottom display="block" >
                {finded.FirstName.toLowerCase()} {finded.LastName.toLowerCase()}                  
              </Typography>
            </Grid>
        </Grid>
        }
        { !finded && !isLoading &&
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginBottom: 8}}>
            <Grid item>
              <Avatar className={classes.errorAvatar}>
                <ErrorOutlineOutlinedIcon  style={{ fontSize: 32 }}/>
              </Avatar>              
            </Grid>    
            <Grid item>
              <Typography>{t("register.NotFound")}</Typography>
            </Grid>        
          </Grid>
        }
          
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginBottom: 8}}>
            <Grid item xs={5} sm={3}>
              <Button disabled={isLoading || !finded} fullWidth className={classes.btn}
                variant="contained" color="primary"
                onClick={this.handleSelectSearchAdviser}
                >
                {t("buttons.Select") }
              </Button>
            </Grid>
            <Grid item xs={5} sm={3}>
              <Button fullWidth disabled={isLoading}
                className={classes.btn}
                variant="outlined"              
                color="primary"
                onClick={this.handleCancelDlgSearchAdviser}
                >
                  {t("buttons.Cancel") }
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      </Paper>
    </div>
      </>
    );
  }
}


export default withTranslation()(withWidth()(
  withRouter(
    withStyles(styles, { withTheme: true })(
      connect(
          state => state.register,
          dispatch => bindActionCreators(actionRegister, dispatch)
      )(withCookies(RegisterClient))
    )
  )
));