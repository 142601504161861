// eslint-disable-next-line no-unused-vars
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../../providers/appProvider";
import { Fab, Box, Paper, Grid, TextField } from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CropDinIcon from '@material-ui/icons/CropDin';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next";
// eslint-disable-next-line no-unused-vars
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import BigSlider from "../../Slider/BigSlider";
import MiddleSlider from "../../Slider/MiddleSlider";
import SmallSlider from "../../Slider/SmallSlider";
// import { EditorSmallSlider } from '../../Slider/EditorSmallSlider';
// import {SliderEditor} from '../../Slider/SliderEditor';
import SliderEditor from '../../Slider/SliderEditor';
import HtmlEditor  from '../HtmlEditor/HtmlEditor';
import { roles } from '../../../definitions/roles'

import SaveIcon from "@material-ui/icons/Save";
// import  Slides  from '../../Slides';

const HtmlBlockType = Object.freeze({
    TopLine: 'topline',
    Logo: 'logo',
    BigSlider: 'bigslider',

    HomePageMiddleBlock: 'homepagemiddleblock',
    MiddleSlider: 'middleslider',

    HomePageBottomBlock: 'homepagebottomblock',
    BottomSlider: 'bottomslider',

});

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: 1,
            padding: theme.spacing(1),
            flexGrow: 1,
            width: "100%",
        },
        paper: {
            padding: theme.spacing(1),
            borderRadius: 8,
            boxShadow: theme.palette.ambilight.boxshadow
        },
        boxWrap: {
            position: "relative",
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
            minHeight: theme.spacing(6),
            display: 'block',
            width: "100%",
            border: "1px dashed #dadce0",
            borderTopRightRadius: theme.spacing(1),
            // borderRadius: theme.spacing(1),
        },
        topLine: {
            color: "#fff",            
            background: "linear-gradient(338deg, rgba(116,251,253,1) 0%, rgba(85,209,231,1) 24%, rgba(50,162,206,1) 100%)"
        },
        logo: {
            marginTop: theme.spacing(1),
            width: "280px",
            maxWidth: "100%",
            height: "auto",

        },
        topLineTxt: {
            "& *": {
                color: "#fff",
                margin: 0,
                padding: theme.spacing(1),
                whiteSpace: "nowrap",
                textDecoration: "none",
            }
        },
        editbtn: {
            position: 'absolute',
            top: theme.spacing(-1),
            right: theme.spacing(-1 / 2),
        },
        fullscreenButton: {
            position: 'absolute',
            right: theme.spacing(5),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogContent: {
            minHeight: theme.spacing(60)
        }
    })
);

const DialogTitle = ((props) => {
    const { children, onClose, onFullScreen, ...other } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onFullScreen ? (
                <IconButton size="small" aria-label="fullscreen" className={classes.fullscreenButton} onClick={onFullScreen}>
                    <CropDinIcon />
                </IconButton>
            ) : null}

            {onClose ? (
                <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const HomePage = () => {
    const classes = useStyles();
    const app = useContext(AppContext);
    const { lang, country } = app;
    const { t, i18n } = useTranslation();

    // eslint-disable-next-line no-unused-vars
    const [token, setToken] = useState(null);
    // const [usr, setUsr] = useState(null);


    const [topLine, setTopLine] = useState(null);
    const [logo, setLogo] = useState(null);
    const [bigSlider, setBigSlider] = useState(null);
    const [middleBlock, setMiddleBlock] = useState(null);
    const [middleSlider, setMiddleSlider] = useState(null);
    const [bottomBlock, setBottomBlock] = useState(null);
    const [bottomSlider, setBottomSlider] = useState(null);

    const refHtmlEditor = useRef();
    const refSliderEditor = useRef();
    
    const [openLogo, setOpenLogo] = useState(false); 
    const [open, setOpen] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [contentTypeDialog, setContentTypeDialog] = useState();

    const [langs, setLangs] = useState([]);
    const [countries, setCountries] = useState([]);

    const FILE_BIGSLIDER = "hp-big-slider";
    const FILE_MIDDLESLIDER = "hp-middle-slider";
    const FILE_BOTTOMSLIDER = "hp-small-slider";

    const handleChangeColumns = (e) => {
        setLogo(e.target.value);
    };

    const handleClickOpenLogo = () => {
        setOpenLogo(true);
    };

    const handleCloseLogo = () => {
        setOpenLogo(false);
    };

    const handleClickOpen = (block) => {
        // console.log(block);        
        setContentTypeDialog(block);
        setOpen(true);
    };

    const handleFullScreen = () => {
        // console.log('fullScreen', fullScreen);
        setFullScreen(!fullScreen);
    };

    const handleClose = () => {
        setOpen(false);
        setFullScreen(false);
    };

    const handleSave = () => {
        
        console.log(contentTypeDialog);
        switch (contentTypeDialog) {
            
            case MiddleSlider.BigSlider:
            case MiddleSlider.BottomSlider:
            case HtmlBlockType.BottomSlider:
                refSliderEditor.current.updateData();
                break;                    
            default:
                refHtmlEditor.current.updateData();
                break;
        }
        
    };

    const SaveLogo = () => {

        const URL = "/api/v1/content/savelogo";
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        fetch(URL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ "logo": logo })
        })
            .then(response => response.json())
            .then(saved => {
                console.log(saved);
            })
            .catch((error) => {
                console.log("Error SaveHtmlBlock", error);

            })
            .finally(() => {
                // setLoading(false);
            });

    };

    const SaveSlider = (slider) => {
        console.log('Save slider', slider);
    };

    const SaveHtmlBlock = (doc, typeblock) => {
        if (!typeblock) return;

        // console.log('Save html block', doc, typeblock);

        const URL = "/api/v1/content";
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        fetch(URL, {
            method: doc.Id === 0 ? "POST" : "PUT",
            headers: headers,
            body: JSON.stringify(doc)
        })
            .then(response => response.json())
            .then(saved => {                
                switch (typeblock) {
                    case HtmlBlockType.TopLine:
                        setTopLine(saved);
                        break;
                    case HtmlBlockType.HomePageMiddleBlock:
                        setMiddleBlock(saved);
                        break;
                    case HtmlBlockType.HomePageBottomBlock:
                        setBottomBlock(saved);
                        break;
                    default:
                        break;
                }
                // setOpen(false);
                setFullScreen(false);
            })
            .catch((error) => {
                console.log("Error SaveHtmlBlock", error);
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    const loadLogo = async () => {
        // const URL = `/data/site.json?v=${parseInt((new Date().getTime() / 1000).toFixed(0))}`;        
        const URL = "/api/v1/content/getlogo";
        fetch(URL, {
            method: "GET"
        }
        )
            .then(response => response.json())
            .then(data => {
                setLogo(data.logo);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const loadHtmlBlock = useCallback(async (token, key, typeblock, Langs) => {      
        // const URL = `/api/v1.0/content/${key}/true/uk`;
        const URL = `/api/v1.0/content/${key}/true/${lang}/${country}`;
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        fetch(URL, { method: "GET", headers: headers })
            .then(response => response.json())
            .then(cont => {
                if(cont){
                    // console.log("typeblock", typeblock, cont); 

                    for (let i = 0; i < Langs.length; i++) {                        
                        const lang = Langs[i];
                        const idx = cont.LocalValues.findIndex(l => l.TwoLetterISOLanguageName === lang.TwoLetterISOLanguageName);                                                
                        if(idx > -1){
                            continue;
                        }                                                
                        const localValue = {
                            $type: "Start.Doing.Module.Core.Models.LocalValue, Start.Doing.Module.Core",
                            Annonce: "",
                            ContentId: cont.Id,
                            CreatedOn: cont.CreatedOn,
                            Descr: "",
                            Id: 0,
                            Keywords: "",
                            Title: "",
                            TwoLetterISOLanguageName: lang.TwoLetterISOLanguageName,
                            UpdatedOn: cont.CreatedOn, 
                            Value: ""};
                        
                        cont.LocalValues.push(localValue);
                    }
                    
                    

                    switch (typeblock.toLowerCase()) {
                        case HtmlBlockType.TopLine:
                            setTopLine(cont);
                            break;
                        case HtmlBlockType.HomePageMiddleBlock:
                            setMiddleBlock(cont);
                            break;
                        case HtmlBlockType.HomePageBottomBlock:
                            setBottomBlock(cont);
                            break;
                        default:
                            break;
                    }
                }

            })
            .finally(() => {
                // setLoading(false);
            });
    }, [country, lang]);

    const loadSlider =  async (token, name) => {
        const URL = `/api/v1.0/slider/load/${name}/true`;

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Authorization", token);

        fetch(URL, { method: "GET", headers: headers })
            .then(response => response.json())
            .then(slider => {                
                if (name === FILE_BIGSLIDER)
                    setBigSlider(slider);

                if (name === FILE_MIDDLESLIDER)
                    setMiddleSlider(slider);

                if (name === FILE_BOTTOMSLIDER)
                    setBottomSlider(slider);

            })
            .finally(() => {
                // setLoading(false);
            });
    };

    const dialogContent = () => {
        if (langs.length === 0)
            return;
        switch (contentTypeDialog) {
            case HtmlBlockType.TopLine:
                // console.log(topLine);
                return  <HtmlEditor
                            ref={refHtmlEditor}
                            roles={roles}
                            token={token}
                            typeblock={HtmlBlockType.TopLine}
                            keyDisabled={false}
                            article={topLine}
                            langs={langs}
                            onSave={SaveHtmlBlock} 
                        />
            case HtmlBlockType.BigSlider:
                return  <SliderEditor
                            ref={refSliderEditor}
                            slider={bigSlider}
                            roles={roles} 
                            langs={langs}
                            countries={countries}
                            onSave={SaveSlider}
                        />
            case HtmlBlockType.MiddleSlider:
                return  <SliderEditor
                            ref={refSliderEditor}
                            slider={middleSlider}
                            roles={roles} 
                            langs={langs}
                            countries={countries}
                            onSave={SaveSlider}
                        />
            case HtmlBlockType.BottomSlider:
                return  <SliderEditor
                            ref={refSliderEditor}
                            slider={bottomSlider}
                            roles={roles}
                            langs={langs}
                            countries={countries}
                            onSave={SaveSlider}
                        />
            case HtmlBlockType.HomePageMiddleBlock:
                return  <HtmlEditor
                            ref={refHtmlEditor}
                            roles={roles}
                            token={token}
                            typeblock={HtmlBlockType.HomePageMiddleBlock}
                            keyDisabled={false}
                            article={middleBlock}
                            onSave={SaveHtmlBlock}
                            langs={langs}
                        />
            case HtmlBlockType.HomePageBottomBlock:
                return  <HtmlEditor
                            ref={refHtmlEditor}
                            roles={roles}
                            token={token}
                            typeblock={HtmlBlockType.HomePageBottomBlock}
                            keyDisabled={false}
                            article={bottomBlock}
                            onSave={SaveHtmlBlock}
                            langs={langs}
                        />
            default:
                return <Typography align='center' display='block' color='error'>Undefined block</Typography>;
        }
    };

    useEffect(() => {
        if (!token)
            return;

        fetch('/api/v1.0/Utils/GetCultures',
            { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setLangs(data);                    
                    loadLogo();
                    loadHtmlBlock(token, 'topline', HtmlBlockType.TopLine, data);
                    loadHtmlBlock(token, 'home_page_middle_block', HtmlBlockType.HomePageMiddleBlock, data);
                    loadHtmlBlock(token, 'home_page_bottom_block', HtmlBlockType.HomePageBottomBlock, data);
                    loadSlider(token, FILE_BIGSLIDER);
                    loadSlider(token, FILE_MIDDLESLIDER);
                    loadSlider(token, FILE_BOTTOMSLIDER);
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });
    }, [loadHtmlBlock, token]);

    useEffect(() => {
        if(!token) return;
                
        fetch('/api/v1.0/Utils/Countries', { headers: { authorization: token, accept: 'Accept: application/json' } })
            .then((response) => response.json())
                .then((data) => {
                    if (data) {                        
                        setCountries(data);
                    }
                })
            .finally(() => {
            });

    }, [token]);

    useEffect(() => {
        function fetchUser() {
            app.getUser().then(user => {
                let token = null;
                if (user != null) {
                    // console.log(user);
                    token = `Bearer ${user.access_token}`;
                }
                setToken(token);
            });
        }
        fetchUser();
    }, [app]);

    return (
        <AppConsumer>
            {() => (
                <div className={classes.root}>
                    <Paper elevation={0} className={classes.paper}>
                        <Typography variant='h5' display='block'  gutterBottom>Editor: Home page</Typography>

                        {/* Top line */}
                        <Box className={classes.boxWrap}>
                            <Typography variant='subtitle2' color="textSecondary" align='center' gutterBottom display='block'>Top line</Typography>
                            <Fab size="small" className={classes.editbtn} aria-label="topLine" onClick={() => handleClickOpen(HtmlBlockType.TopLine)} >
                                <EditOutlinedIcon />
                            </Fab>
                            {topLine &&
                                <Typography className={classes.topLine} align="center" display="block" component="div" variant="body2" noWrap>
                                    <div className={classes.topLineTxt} dangerouslySetInnerHTML={{ __html: topLine.LocalValues[0].Value }}></div>
                                </Typography>
                            }
                        </Box>
                        
                        {/* Site logo */}
                        <Box className={classes.boxWrap}>
                            <Typography variant='subtitle2' color="textSecondary" align='center' gutterBottom display='block'>Site logo</Typography>
                            <Fab size="small" className={classes.editbtn} aria-label="topLine" onClick={handleClickOpenLogo} >
                                <EditOutlinedIcon />
                            </Fab>
                            {logo &&
                                <Box style={{ textAlign: 'center' }}>
                                    <img className={classes.logo} alt="Jerelia" src={logo} />
                                </Box>
                            }
                        </Box>
                        
                        {/* Big slider */}
                        <Box className={classes.boxWrap}>
                            { bigSlider && 
                            <>
                                <Typography variant='subtitle2' color='textSecondary' align='center' gutterBottom display='block'>
                                    {bigSlider.Name}: {bigSlider.Items.length}
                                </Typography>
                                
                                <div>
                                    <BigSlider slider={bigSlider} autoplay={false} />
                                </div>
                                <Fab size="small" className={classes.editbtn} aria-label="topLine"
                                    onClick={() => handleClickOpen(HtmlBlockType.BigSlider)} >
                                    <EditOutlinedIcon />
                                </Fab>
                            </>
                            }
                        </Box>
                        
                        {/* Home page HTML middle block */}
                        <Box className={classes.boxWrap}>
                            <Paper className={classes.paper}>
                                <Typography variant='subtitle2' color='textSecondary' align='center' gutterBottom display='block'>Middle block</Typography>
                                <Fab size="small" className={classes.editbtn} aria-label="topLine" onClick={() => handleClickOpen(HtmlBlockType.HomePageMiddleBlock)} >
                                    <EditOutlinedIcon />
                                </Fab>
                                {middleBlock ?
                                    <Typography display="block" component="div" variant="body2">
                                        <div dangerouslySetInnerHTML={{ __html: middleBlock.LocalValues[0].Value }}></div>
                                    </Typography> :
                                    <Typography variant='body2' color="textSecondary" align='center' display='block'>Empty block </Typography>
                                }
                            </Paper>
                        </Box>
                        
                        {/* Middle slider block  */}                        
                        <Box className={classes.boxWrap}>
                            {middleSlider &&
                            <>
                                <Typography variant='subtitle2' color='textSecondary' align='center' gutterBottom display='block'>
                                    {middleSlider.Name}:  {middleSlider.Items.length}
                                </Typography>
                                <div>
                                    <MiddleSlider slider={middleSlider} />
                                </div>
                                <Fab size="small" className={classes.editbtn} aria-label="topLine"
                                    onClick={() => handleClickOpen(HtmlBlockType.MiddleSlider)} >
                                    <EditOutlinedIcon />
                                </Fab>
                            </>
                            }
                        </Box>
                        
                        {/* Home page bottom block */}
                        <Box className={classes.boxWrap}>
                            <Paper className={classes.paper}>
                                <Typography variant='subtitle2' color='textSecondary' align='center' gutterBottom display='block'>Bottom block</Typography>
                                <Fab size="small" className={classes.editbtn} aria-label="bottomblock" onClick={() => handleClickOpen(HtmlBlockType.HomePageBottomBlock)}>
                                    <EditOutlinedIcon />
                                </Fab>
                                {bottomBlock ?
                                    <Typography display="block" component="div" variant="body2">
                                        <div dangerouslySetInnerHTML={{ __html: bottomBlock.LocalValues[0].Value }}></div>
                                    </Typography> :
                                    <Typography variant='body2' color="textSecondary" align='center' display='block'>Empty block</Typography>
                                }
                            </Paper>
                        </Box>

                        {/* Bottom slider block  */}
                        <Box className={classes.boxWrap}>
                            {bottomSlider &&
                            <>
                                <Typography variant='subtitle2' color='textSecondary' align='center' gutterBottom display='block'>
                                    {bottomSlider.Name}: {bottomSlider.Items.length}
                                </Typography>
                                <div>
                                    <SmallSlider slider={bottomSlider} />
                                </div>
                                <Fab size="small" className={classes.editbtn} aria-label="topLine" onClick={() => handleClickOpen(HtmlBlockType.BottomSlider)} >
                                    <EditOutlinedIcon />
                                </Fab>
                            </>
                            }
                        </Box>
                        
                    </Paper>

                    <Dialog onClose={handleClose} aria-labelledby="dialog-title-content" open={open} fullWidth={true} maxWidth="xl" fullScreen={fullScreen}>
                        <DialogTitle id="dialog-title-content" onClose={handleClose} onFullScreen={handleFullScreen}>
                            <small>Block:</small> {contentTypeDialog}
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent} dividers>
                            {dialogContent()}
                        </DialogContent>
                        <DialogActions style={{paddingRight: 32}}>
                            <Button className={classes.button} variant='outlined' onClick={handleClose} color="default">
                                {t("buttons.Close")}
                            </Button>
                            <Button className={classes.button} variant='outlined' autoFocus onClick={handleSave} color="primary">
                                {t("buttons.Save")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog onClose={handleCloseLogo} aria-labelledby="dialog-title-content" open={openLogo} fullWidth={true} maxWidth="xl" fullScreen={fullScreen}>
                        <DialogTitle id="dialog-titlelog-content" onClose={handleCloseLogo} >
                            Logo
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        variant='outlined'
                                        startIcon={<SaveIcon />}
                                        onClick={SaveLogo}
                                    >
                                        {t("buttons.Save")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={8} md={10}>
                                    <TextField
                                        value={logo}
                                        fullWidth
                                        variant='outlined'
                                        onChange={handleChangeColumns}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                    <img className={classes.logo} alt="Jerelia" src={logo} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleCloseLogo} color="default">
                                {t("buttons.Close")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            )}
        </AppConsumer>
    )
}

