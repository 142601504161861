import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card ,CardActionArea, CardMedia, CardContent,  CardActions, Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
// import StarIcon from "@material-ui/icons/StarBorder";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST: "";

const useStyles = makeStyles((theme) =>
  createStyles({    
    card: {
      borderRadius: 16,      
      height: '100%',
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",      
      // boxShadow: theme.palette.ambilight.boxshadow
    },
    cardcontent: {
    //   minHeight: theme.spacing(18)
    },
    first: {
        fontSize: "1.3rem"
    },
    secondary: {
        height: theme.spacing(9),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
    },
    cardActions: {
      paddingBottom: theme.spacing(3),
    },
    media: {
      paddingTop: "56.25%", // 16:9
    }    

  })
);

const MiddleSlide = (props) => {        
    const classes = useStyles();
    const { t } = useTranslation();    
    const {slide } = props;
    // console.log('Middle slide' ,slide);
    const history = useHistory();

    const handleClickByCard = (e) => {
        e.stopPropagation();
        if(slide.Url !== ""){
            if(slide.ExternalLink)
                window.open(slide.Url, "_blank", "noreferrer");
            else
                history.push(slide.Url);
        }
    }

    return (      
      <Card className={classes.card}>
        <CardActionArea onClick={handleClickByCard}>
        <CardMedia
            className={classes.media}
            image={`${host}${slide.FullPath}`}
            title={slide.Title}
        />
        <CardContent className={classes.cardcontent}>
            <Typography className={classes.first} gutterBottom variant="h5" component="div" display='block' align='center'>
                {slide.LocalValues[0].Caption === "" ? slide.Caption : slide.LocalValues[0].Caption}
            </Typography>
            <Typography className={classes.secondary} variant="body1" color="textSecondary" display='block' align='center'>
            {slide.LocalValues[0].Text === "" ? slide.Text: slide.LocalValues[0].Text} 
            </Typography>
        </CardContent>
        </CardActionArea>
        
        {slide.ButtonText !== '' && 
        <CardActions className={classes.cardActions} >
            <Grid container direction="row" spacing={2} justifyContent="center"  alignItems="center">
                <Grid item xs={10} sm={8} md={6}>
                {slide.ExternalLink ? 
                    <a href={slide.Url} target="_blank" rel='noreferrer' style={{width: '100%'}}>
                        <Button size='small' fullWidth >
                            {slide.LocalValues[0].ButtonText === "" ? slide.ButtonText: slide.LocalValues[0].ButtonText}
                        </Button>
                    </a> :
                    <Button variant="outlined" color="primary" to={slide.Url} fullWidth component={Link}>
                        {slide.LocalValues[0].ButtonText === "" ? slide.ButtonText: slide.LocalValues[0].ButtonText}
                    </Button>
                }
                </Grid>
            </Grid>              
        </CardActions>
        }
      </Card>      
    )
}

MiddleSlide.propTypes = {  
  slide:                         
    PropTypes.shape({
      Id: PropTypes.number.isRequired,      
      Caption: PropTypes.string.isRequired,
      Url: PropTypes.string.isRequired,
      ExternalLink: PropTypes.bool.isRequired,
      Text: PropTypes.string.isRequired,
      Path: PropTypes.string.isRequired,
      // Img: PropTypes.string.isRequired,
      FullPath: PropTypes.string.isRequired,
      IsAuthenticated: PropTypes.bool.isRequired,
      Roles: PropTypes.arrayOf(PropTypes.string),
      Hide: PropTypes.bool.isRequired,
      ShowText: PropTypes.bool.isRequired,
      ShowFrom: PropTypes.string.isRequired,
      ShowTo: PropTypes.string.isRequired,  
      ButtonText: PropTypes.string.isRequired,
      LocalValues: PropTypes.arrayOf(
        PropTypes.shape({
            Id: PropTypes.number.isRequired,
            ButtonText: PropTypes.string.isRequired,
            Caption: PropTypes.string.isRequired,
            Text: PropTypes.string.isRequired,
            TwoLetterISOLanguageName: PropTypes.string.isRequired,            
        })
    ).isRequired,
    }).isRequired,
};

export default MiddleSlide;