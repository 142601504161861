import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { actionRegister } from "../../store/Register";
import clsx from "clsx";
import { Typography, Paper, Grid } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import lightBlue  from "@material-ui/core/colors/lightBlue";
import { CustomerState } from "../../definitions/customer";
import CheckIcon from "@material-ui/icons/Check";

// import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";

// eslint-disable-next-line no-undef
const host = process.env.NODE_ENV === "development"? "https://jerelia.com": "";

function getUserName(userName){    
  const words = userName.split(" ");
  const res = (`${words[1].charAt(0)}${words[0].charAt(0)}`).toUpperCase();
  // console.log("USER NAME", res);
  return res;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {        
      padding: theme.spacing(2,8),
    },
    
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",    
    minHeight: theme.spacing(8),
    borderRadius: theme.spacing(2),
    boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  },
  paperHeader: {
    padding: theme.spacing(1),
    textAlign: "center",        
  },
  btn: {
    borderRadius: theme.spacing(3),    
  },
  btn_choise: {
    height:'auto',
    [theme.breakpoints.down("sm")]: {
        height: theme.spacing(8),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    // border: "3px solid #48bfde",
    fontSize: "3em",
    lineHeight: "3em",
    color: theme.palette.getContrastText(lightBlue[700]),
    backgroundColor: theme.palette.primary.main,
    letterSpacing: "-0.1em",
    // [theme.breakpoints.down("xs")]: {        
    //     display: "none",           
    // },
  },
  mentor: {
    textTransform: "capitalize"
  },  
  txtBlockConsultant: {
    textAlign: 'left',
    height: "auto",    
    [theme.breakpoints.down("sm")]: {        
      height: 230,
    },
  },
  txtBlockClient: {
    textAlign: 'left',
    height: "auto",
    [theme.breakpoints.down("sm")]: {        
      height: 230,
    },
  },

});

// const formatPhoneNumber = (str) => {
//   //Filter only numbers from the input
//   let cleaned = ("" + str).replace(/\D/g, "");
  
//   //Check if the input is of correct
//   let match = cleaned.match(/^(1|)?(\d{3})(\d{2})(\d{3})(\d{4})$/);
  
//   if (match) {
//     //Remove the matched extension code
//     //Change this to format for any country code.
//     let intlCode = (match[1] ? "380 " : "");    
//     return [intlCode, match[2], " (", match[3], ") ", match[4], "-", match[5]].join("")
//   }
//   return null;
// }
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {        
        isLoading: false,
        ref: null,
    };
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  getRefId = () => {
    const { cookies } = this.props;
    const query = new URLSearchParams(window.location.search.toLowerCase());
    let ref = this.props.match.params.ref || "";
    if( ref === undefined || ref.length === 0){
        const fromquery = query.getAll("ref");
        if(fromquery.length !== 0){
            ref = fromquery.toString();
        }
        
        if(ref.length === 0){
            // const { cookies } = this.props;
            // ref = cookies.get("ref");
            ref = window.sessionStorage.getItem("ref");
            this.setState({ ref: ref});
        }
    }
    
    if(ref){
        // const referrer = document.referrer;        
        // const referrerUrl = new URL(referrer ? referrer : window.location.href);
        // const utm_source = referrerUrl.hostname;
        // const utmSource =  (utm_source.startsWith('www')) ? 
        //                     utm_source.replace('www.', ''):
        //                     utm_source;

        // const date = new Date();
        // date.setDate(date.getDate() + 30);
        
        // cookies.set('utm_source', utmSource, { expires: date, path: '/' })
        
        this.props.requesMentor(ref);        
    }
  };
  
  componentDidMount() {
    this.getRefId();    
    window.scrollTo({top: 0, behavior: "smooth"});
  }

  handleChange = (event, newValue) => {
    // this.setState({ value: newValue});
    this.props.changeCustomerState(newValue);
  };

  handleClickConsultant = () => {
    const { refId} = this.props;
    this.props.changeCustomerState(0);
    // this.handleClickStepOne();    
    this.props.history.push(`/register_consultant/${refId ? refId : "" }`);
  }
  
  handleClickClient = () => {
    const { refId} = this.props;
    this.props.changeCustomerState(1);
    this.props.history.push(`/register_client/${refId ? refId : ""}`);
    // this.handleClickStepOne();
    // const { refId} = this.props;    
    // this.props.history.push(`/client_step1/${refId}`);    
  }

  handleClickStepOne = () => {
    const { customerState, refId} = this.props;
    if(customerState === 0){
      this.props.history.push(`/register_consultant/${refId ? refId : "" }`);
    }else{
      this.props.history.push(`/register_client/${refId ? refId : ""}`);
    }    
  }


  render(){
    const { classes, customerState, mentor, t } = this.props;
    // const { ref } = this.state;

    const shortName = mentor ?  getUserName(mentor.Name) : "";

    return (
      <div className={classes.root}> 
        <Helmet>
          <title>{ `Jerelia, ${t("register.title")}` }</title>
        </Helmet>

        <Paper className={classes.paper} elevation={0}>
        { mentor && 
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{marginBottom: 16}}>
          <Grid item>
          {
            mentor.Avatar && mentor.Avatar.length > 0 ?                                         
            <Avatar alt={shortName} src={`${host}${mentor.Avatar}`} className={classes.large} /> : 
            <Avatar className={classes.large}>
                { shortName }
            </Avatar>
        }
          </Grid>
          <Grid item >
            <Typography variant="subtitle2">Ваш наставник:</Typography>
            <Typography className={classes.mentor} gutterBottom>{mentor.FirstName.toLowerCase()} {mentor.LastName.toLowerCase()}</Typography>
            <Typography variant="body2">Номер наставника:</Typography>
            <Typography variant="h6">{mentor.RegistrationCode}</Typography>
          </Grid>
        </Grid> }

        <Typography variant="h5" gutterBottom align="center">{t("register.title")}</Typography>
        <Typography variant="body1" gutterBottom align="center">
            {t("register.textLine1")}
        </Typography>
        <Typography variant="body2"  align="center">
            {t("register.textLine2")}
        </Typography>
        <Typography variant="body2"  align="center">
            {t("register.textLine3")}
        </Typography>
        <Typography variant="body2" gutterBottom align="center">
            {t("register.textLine4")}
        </Typography>
        <br />
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={6} sm={3}>
            <Button fullWidth className={clsx(classes.btn_choise)}
              variant={customerState === CustomerState.Consultant ? "contained": "outlined"}
              size={customerState === CustomerState.Consultant ? "large": "medium"} 
              startIcon={ customerState === CustomerState.Consultant ? <CheckIcon /> : null}
              color="primary" onClick={(e) => this.handleChange(e, CustomerState.Consultant)}>
              {t("register.btnCunsultant")}
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button fullWidth className={clsx(classes.btn_choise)}
              variant={customerState === CustomerState.AuthorizedUser ? "contained": "outlined"} 
              size={customerState === CustomerState.AuthorizedUser ? "large": "medium"} 
              startIcon={ customerState === CustomerState.AuthorizedUser ? <CheckIcon /> : null}
              color="primary" onClick={(e) => this.handleChange(e, CustomerState.AuthorizedUser)}>
                {t("register.btnClient")}
            </Button>
          </Grid>
        </Grid>

        {/* <Tabs value={value} onChange={handleChange} centered aria-label="tabs register first page">
          <Tab label="Консультант" {...a11yProps(0)} />
          <Tab label="Клієнт" {...a11yProps(1)} />          
        </Tabs> */}

        <TabPanel value={customerState} index={0}>
          {/* <Typography variant="h6" gutterBottom>{t("register.consultantTextHead")}</Typography> */}
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <Typography className={classes.txtBlockConsultant} component="ul" variant="body2" gutterBottom >
                <li>{t("register.consultantTextLine1")}</li>
                <li>{t("register.consultantTextLine2")}</li>
                <li>{t("register.consultantTextLine3")}</li>
                <li>{t("register.consultantTextLine4")}</li>
                <li>{t("register.consultantTextLine5")}</li>
                <li>{t("register.consultantTextLine6")}</li>
                <li>{t("register.consultantTextLine7")}</li>
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={customerState} index={1}>          
          {/* <Typography variant="h6" gutterBottom>{t("register.clientTextHead")}</Typography> */}
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <Typography className={classes.txtBlockClient} component="ul" variant="body2" gutterBottom >
                <li>{t("register.clientTextLine1")}</li>
                <li>{t("register.clientTextLine2")}</li>
                <li>{t("register.clientTextLine3")}</li>
                <li>{t("register.clientTextLine4")}</li>
                <li>{t("register.clientTextLine5")}</li>
                <li>{t("register.clientTextLine6")}</li>            
            </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={3}>
            <Button size="large" 
              className={classes.btn}
              fullWidth 
              variant="contained" endIcon={<ArrowForwardIcon />}
              onClick = {this.handleClickStepOne}
              color="primary">{t("register.btnRegister")}</Button>
          </Grid>
        </Grid>
        </Paper>

        </div>
    );
  }
}

export default withTranslation()(withWidth()(
  withRouter(
    withStyles(styles, { withTheme: true })(
      connect(
          state => state.register,
          dispatch => bindActionCreators(actionRegister, dispatch)
      )(withCookies(Register))
    )
  )
));