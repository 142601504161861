import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Box } from "@material-ui/core";
import {BlockType} from "../../../definitions/pageblocktype";
import TitleBlock from "./TitleBlock";
import HtmlBlock from "./HtmlBlock";
import VideoLinkBlock from "./VideoLinkBlock";
import ImageBlock from "./ImageBlock";
import VideoBlock from "./VideoBlock";
import BigSlider from "../../Slider/BigSlider";
import MiddleSlider from "../../Slider/MiddleSlider";
import SmallSlider from "../../Slider/SmallSlider";
import LogoImage from "./LogoImage";
import BonuseNewcomer from "./Widgets/BonuseNewcomer";
import PdfBlock from "./PdfBlock";


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,            
            justifyItems: 'center',
            justifyContent: 'center'
        },
      
        youtube: {
            // padding: theme.spacing(0, 60),
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(1/2),
            },
        },
        box: {
            display: 'block',
            width: '100%'
        }
    })
);

const host = process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST : "";

const ContainerBlockView = props => {
    const { block, } = props;
    const classes = useStyles();
    
    

  return (
    <div className={classes.root}>
        {block &&  block.Content && 
        <>
            
            {block.PageBlockType === BlockType.TITLE &&
                <Box className={classes.box} p={1}> 
                    <TitleBlock localValue={ block.Content.LocalValues[0]} blockKey={ block.Key } propsBlock={block.Content.Props} />
                </Box>
            }

            {block.PageBlockType === BlockType.HTMLBLOCK &&
                <Box className={classes.box} p={0}> 
                    <HtmlBlock localValue={ block.Content.LocalValues[0]} blockKey={ block.Key }/>
                </Box>
            }

            {block.PageBlockType === BlockType.IMAGE &&
                <Box className={classes.box} p={1}> 
                    <ImageBlock url={ block.Content.LocalValues[0].Value} />
                </Box>
            }
            
            {block.PageBlockType === BlockType.BIGSLIDER && 
            <Box p={1} className={classes.box}> 
                <BigSlider slider={ block.Content } blockKey={ block.Key } autoplay={ false } />
            </Box>
            }

            {block.PageBlockType === BlockType.MIDDLESLIDER && 
                <Box className={classes.box}> 
                    <MiddleSlider slider={ block.Content } blockKey={ block.Key } />
                </Box>
            }

            {block.PageBlockType === BlockType.SMALLSLIDER && 
                <Box p={1} className={classes.box}> 
                    <SmallSlider slider={ block.Content } blockKey={ block.Key } />
                </Box>
            }

            {block.PageBlockType === BlockType.LOGOIMAGE && block.Content && 
                <Box p={1} className={classes.box}> 
                    <LogoImage content={ block.Content } blockKey={ block.Key } />
                </Box>
            }

            {block.PageBlockType === BlockType.WGBONUSENEWCOMER && block.Content.Data &&
                <Box p={1} className={classes.box}> 
                    <BonuseNewcomer data={ block.Content.Data } blockKey={ block.Key } />
                </Box>
            }

            {block.PageBlockType === BlockType.PDFVIEWER && 
                <Box p={1} className={classes.box}> 
                    <PdfBlock url={ block.Content.LocalValues[0].Value } blockKey={ block.Key } />
                </Box>
            }

            {block.PageBlockType === BlockType.VIDEOLINK && 
                <Box className={clsx( classes.youtube, classes.box)}>                    
                    <VideoLinkBlock url={ block.Content.LocalValues[0].Value} shortVideo={block.Content.Props.ShortVideo === 'true' }  />
                </Box>
            }

            {block.PageBlockType === BlockType.VIDEO && 
                <Box p={1} className={classes.box}> 
                    <VideoBlock url={ block.Content.LocalValues[0].Value}  />
                </Box>
            }
                        
        </>                                
        }
    </div>
  )
}

ContainerBlockView.propTypes = {
    block: PropTypes.object.isRequired    
}

export default ContainerBlockView;