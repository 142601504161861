import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { CircularProgress, Typography } from '@material-ui/core';
import { useExternalScript } from "./useExternalScript";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        // paddingTop: theme.spacing(8),
        // [theme.breakpoints.down("xs")]: {            
        //   paddingTop: theme.spacing(1),
        // },
        
      },
      paper: {
        padding: theme.spacing(3, 1),
        boxShadow:
          "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
      },
      paymentbox: {
          minHeight: 355,
          borderRadius: "1rem",
      },
      header: {
        fontWeight: 300,
        marginBottom: theme.spacing(2),
      },
      fondy:{
          // padding: theme.spacing(2),
          // border: `1px solid ${theme.palette.divider}`,        
          // borderRadius: theme.spacing(4),
          [theme.breakpoints.down("xs")]: {            
              margin: theme.spacing( 0, -1, 0 -1),
            },
      },
    })
  );

const FondyComponent = props => {
    const { amount, regnumber } = props;    
    const { t, i18n } = useTranslation();    
    const classes = useStyles();
    const theme = useTheme();
    

    const status = useExternalScript("https://pay.fondy.eu/latest/checkout-vue/checkout.js");

    const callTransfer = useCallback(async () => {
        const url = "/api/v1.0/fondy/transfer";
        const resTransfer = await fetch(url, {
            method: "POST", 
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"                
              },
            body: JSON.stringify({amount: amount, regnumber: regnumber })
        });

        if (resTransfer.status !== 200) {
            return;
        }
        const data = await resTransfer.json();
        if(data){
            const Options = {
                options: {
                  methods: ['card', 'wallets'],              
                  methods_disabled: ['banklinks_eu', 'local_methods','receipt','loans','most_popular'],
                  link: 'https://jerelia.com',
                  active_tab: 'card',
                  card_icons: ['mastercard', 'visa'],
                  logo_url: 'https://jerelia.com/assets/files/images/logos/jerelia.svg',
                  full_screen: true,              
                  title: t("pay.Transfer funds"), // (data.baseId ? t("order.OrderNum", {num: data.title}) : t(data.title).toUpperCase()) ,
                  default_country: 'UA',
                  email: false,
                  countries: ['UA', 'US','DE', 'GB', 'PL'],
                  fields: false,
                  locales: ['cs', 'de', 'en', 'es', 'fr', 'hu', 'it', 'ko', 'lv', 'pl', 'ro', 'sk', 'uk'],
                  theme: {
                    type: theme.palette.type,// "light",
                    preset: "navy_shimmer"
                  },
                },
                css_variable: {
                  main: '#4527a0',
                  main_bg: '#f4f5fa',
                },
                params: {
                  merchant_id: parseInt(data.merchantid),              
                  required_rectoken: 'y',
                  currency: data.currency,
                  lang: i18n.language,
                  order_id: data.docid,
                  amount: data.amount,
                  order_desc: t("OrderDescription", {str: data.description}),
                  // custom: data,
                  custom: {
                    "docid": data.docid,
                    "title": data.title,
                    "amount": data.amount,
                    "baseid": data.baseid,
                    "doctype": data.doctype,
                    "currency": data.currency,
                    "merchantid": data.merchantid,
                    "description": `Customer ${data.description}`
                  },
                  
                  server_callback_url: data.server_callback_url
                }
              };
    
              const app = fondy("#fondy_checkout", Options);
              app.$on('success', function (model) {
                
                if(process.env.NODE_ENV === "development"){
                    console.log('Fondy success');
                    console.log(model);
                  }
    
                  if(model.data.order.response_status === "success" && model.data.order.order_data.order_status === "approved"){                        
                    const additional_info = JSON.parse(model.data.order.order_data.additional_info);
                    if(process.env.NODE_ENV === "development")
                      console.log("additional_info", additional_info);
                    
                    const reservation_data = JSON.parse(additional_info.reservation_data);
                    
                    if(process.env.NODE_ENV === "development")
                      console.log("reservation_data", reservation_data);
    
                    setTimeout(() => {
                      dispatch(actionCreators.getCustomer(token));
                    }, 2000);
    
                    if(reservation_data && reservation_data.base_id){
                      history.push(`/orderfin/reg/pp/${reservation_data.base_id}`); 
                    }
                  }
              });
          
              app.$on('error', function (model) {
                  console.log('error event handled', model);
              });
        }

    }, [amount, i18n.language, regnumber, t, theme]);


    useEffect( () => {
        callTransfer()
    }, [status, amount, callTransfer]);

    return (
        <div className={classes.root}>
            <Helmet>              
              <link rel='stylesheet' href='https://pay.fondy.eu/latest/checkout-vue/checkout.css' />                            
            </Helmet>
            {!window.fondy && 
                <Typography component='div' display="block" align="center" style={{paddingTop: 64}}>
                  <CircularProgress size={80} />
                </Typography>                
              }
            <div className={classes.fondy} id="fondy_checkout"></div>
        </div>
    )
}

FondyComponent.propTypes = {
    regnumber: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    username: PropTypes.string,
}

export default FondyComponent;