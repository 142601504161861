import React, { useState, useContext, useEffect, useCallback } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import {  useParams } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
    IconButton,
    Stepper,
    Step,
    StepLabel,
    Grid,
    Chip,
    Avatar,
    Paper,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Typography,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
import LyqpayComponent from "./LyqpayComponent"; 
import FondyComponent from "./FondyComponent";

const host = process.env.NODE_ENV === "development"? "https://jerelia.com": "";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(4, 2),
            borderRadius: theme.spacing(2),
            boxShadow: theme.palette.ambilight.boxshadow,                                    
        },
        paymentbox: {
            minHeight: 355,
            position: "relative",            
        },        
        logo: {
            height: 32,
            marginTop: 2,
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        liqpay: {
            height: 32,
        },
        balance: {
            // fontWeight: 400,
            fontSize: "1.1rem",
        },
        small: {
            fontSize: "0.6rem !important",
        },
        customer: {
            textTransform: "capitalize",          
        },        
        bal_img: {
            color: theme.palette.primary.light,
            fontSize: theme.spacing(7),
            display: "none",
            [theme.breakpoints.up("sm")]: {
                position: "absolute",
                display: "block",
                top: theme.spacing(2),
                right: 8,
                fontSize: theme.spacing(11),
                right: theme.spacing(3),
            },
            filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.1))",
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(1),
        },
        adviser: {
            display: 'flex',
            '& > *': {
              margin: theme.spacing(1),
            },
        }
    })
);


const TransferFunds = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const app = useContext(AppContext);
    const { regnum, pamount } = useParams();
    const [regnumber, setRegNumber] = useState("");
    const [amount, setAmount] = useState(0.00);
    const [adviser, setAdviser] = useState(null);
    const [country, setCountry] = useState(null);
    const [payment, setPayment] = useState(null);
    const ref = React.useRef(null);
    const [selection, setSelection] = React.useState(null);

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = [t('rolesofcustomers.consultant'), t('pay.Amount'), t('order.PaymentInfo')]; //getSteps();

    const searchAdviser = useCallback(async (regnumber) => {
        if(regnumber === "" || regnumber.length < 6) {
            return;
        }

        const response = await fetch(
            `/api/v1.0/adviser/getcustomerbyregistrationcode/${regnumber}/0`,
            {
                method: "GET",
            }
        );

        if (response.status !== 200) {
            return setAdviser(null);
        }
        const adviserData = await response.json();
        // console.log(adviserData);
        setAdviser(adviserData);
    }, []);

    const getCountry = useCallback(async (iso) => {        
        const response = await fetch(`/api/v1.0/payment/countrybyiso/${iso}`);

        if (response.status !== 200) {
            return setCountry(null);
        }
        const countryData = await response.json();        
        setCountry(countryData);
    }, []);

    const getPaymentProvider = useCallback(() => {
        if(!payment || !adviser) {
            return null;
        }            
                
        if(payment.Name === "Fondy"){
            return (
                <FondyComponent 
                    regnumber={adviser.Code} 
                    amount={amount} 
                    title={t("pay.Recharge")} 
                    avatar={`${host}${adviser.Avatar}`} 
                    username={`${adviser.FirstName} ${adviser.LastName}`}
                />
            );
        }
                
        return (
            <LyqpayComponent 
                regnumber={adviser.Code} 
                amount={amount} 
                title={t("pay.Recharge")} 
                avatar={`${host}${adviser.Avatar}`} 
                username={`${adviser.FirstName} ${adviser.LastName}`}
            />
        );
    },[adviser, amount, payment, t]);

    const handeleKeyPress = (e) => {
        if(e.keyCode === 13) {
            searchAdviser(regnumber);
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleClickSearchRegnumber = () => {
        searchAdviser(regnumber);
    };

    const handleMouseDownSearchRegnumber = (event) => {
        event.preventDefault();
    };

    const handleClearAdviser = () => {
        setAdviser(null);
        setCountry(null);
        setPayment(null);
        setRegNumber("");
    };

    useEffect(() => {
        if(regnum) {
            setRegNumber(regnum);
            searchAdviser(regnum);
        }

        if(pamount){
            
            const parsed = parseFloat(pamount);
            if (!Number.isNaN(parsed)) {
                setAmount(parsed);
                setActiveStep(1);    
            }
        }
    }, [regnum, pamount, searchAdviser])
    
    useEffect(() => {
        if(adviser) {
            getCountry(adviser.Country);
        }
        else{
            setCountry(null);
        }
    }, [adviser, getCountry])

    const getStepContent = (step) => {
        
        switch (step) {
            case 0:
                return (
                    <FormControl variant="outlined" margin="normal">
                        <InputLabel htmlFor="outlined-adornment-regnumber">
                            {t("register.RegNumber")}
                        </InputLabel>
                        <OutlinedInput
                            ref={ref}
                            id="outlined-adornment-regnumber"
                            value={regnumber}
                            onChange={(e) => {
                                    // setRegNumber(e.target.value)
                                    setRegNumber(e.target.value.replace(/ /g, "_"));
                                    setSelection([e.target.selectionStart, e.target.selectionEnd]);
                                }
                            }
                            onKeyDown={handeleKeyPress}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="search adviser"
                                        onClick={handleClickSearchRegnumber}
                                        onMouseDown={
                                            handleMouseDownSearchRegnumber
                                        }
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="clear adviser"
                                        onClick={handleClearAdviser}
                                        onMouseDown={handleClearAdviser}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={90}
                        />
                    </FormControl>                    
                );
            case 1:
                return (country &&                    
                        <FormControl variant="outlined" margin="normal">
                          <InputLabel htmlFor="outlined-adornment-amount">
                            {t("pay.Amount")}{" "}
                          </InputLabel>
                          <OutlinedInput
                            type="number"
                            id="outlined-adornment-amount"
                            value={ parseFloat(amount).toFixed(2) }
                            inputProps={{ min: 1 }}
                            onChange={(e) => setAmount(parseFloat( e.target.value))}
                            startAdornment={
                              <InputAdornment position="start">
                                <span style={{fontSize:'.8em'}}>{country.CurrencyName}</span> 
                              </InputAdornment>
                            }
                            labelWidth={60}
                          />
                        </FormControl>                    
                )                
            case 2:
                return getPaymentProvider();
                // return  <LyqpayComponent 
                //             regnumber={adviser.Code} 
                //             amount={ parseFloat(amount).toFixed(2)} 
                //             title={t("pay.Recharge")} 
                //             avatar={`${host}${adviser.Avatar}`} 
                //             username={`${adviser.FirstName} ${adviser.LastName}`}
                //         />
                ;
            default:
                return null;
        }
    };

    const PaymentProviderLogo = (payment) => {
        const idx = payment.Values.findIndex((p) => p.FieldName === "Logo");
        if (idx > -1) {
          return payment.Values[idx].Value;
        }
        return null;
    };

    const handlePaymentStep = (i) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setPayment(country.PaymentProviders[i]);        
    };

    const getNextButtons = () => {        
        if(activeStep === steps.length - 1){
            return null
            // return (
            //     <Button
            //         variant="contained"
            //         color="primary"
            //         onClick={handleNext}
            //         disabled={!adviser || !country}
            //     >                    
            //         Finish                    
            //     </Button>
            // )
        }
        if(activeStep === 1 && adviser && country){
            return(
                <>
                {country.PaymentProviders.map((payment, i) => (
                    <Button key={payment.Id}                        
                        color="primary"
                        onClick={()=> handlePaymentStep(i)}
                        variant="outlined"                        
                    >
                    
                        <img
                            className={clsx(classes.logo, payment.Name.toLowerCase())}
                            alt={payment.Name}
                            src={PaymentProviderLogo(payment)}
                        /> 
                        <span style={{marginLeft: 8}}>{t("pay.Pay")}</span>
                </Button>
                ))}
                </>
            )
        }

        return (
            <Button                
                color="primary"
                className={classes.button}
                onClick={handleNext}
                disabled={!adviser || !country}
            >                    
                {t("buttons.Next")}
            </Button>
        )
    }

    // const getSteps = () => {
    //     return [t('rolesofcustomers.consultant'), t('pay.Amount'), t('order.PaymentInfo')];
    // }

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12} sm={10} md={8}>
                    <Paper
                        className={clsx(classes.paper, classes.paymentbox)}
                        elevation={0}
                    >                        
                        <span className={clsx("material-icons", classes.bal_img)}>
                            currency_exchange
                        </span>
                        
                        <Typography                            
                            variant="h4"
                            align="center"
                        >
                            {t("pay.Transfer funds")}
                        </Typography>

                        <div style={{ marginTop: 20, textAlign: "center" }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            
                            <div>
                                {activeStep === steps.length ? (
                                    <div>
                                        <Typography
                                            className={classes.instructions}
                                        >
                                            All steps completed
                                        </Typography>
                                        <Button onClick={handleReset}>
                                            Reset
                                        </Button>
                                    </div>
                                ) : (
                                    <div>

                                    { adviser && 
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Typography variant="h6">
                                                    {t("pay.Recipient")}
                                                </Typography>                                                
                                            </Grid>
                                            <Grid item>
                                                <Avatar className={classes.large} alt={`${adviser.FirstName} ${adviser.LastName}`} src={`${host}${adviser.Avatar}`} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h5" className={classes.customer}>
                                                    {`${adviser.Code}, ${adviser.FirstName.toLowerCase()} ${adviser.LastName.toLowerCase()}`} 
                                                </Typography>
                                            </Grid>                            
                                        </Grid>
                                        }

                                        <div className={classes.instructions} >
                                            {getStepContent(activeStep)}
                                        </div>                                                                                                                        
                                        
                                        <div style={{ marginTop: 20 }}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.button}
                                            >
                                                {t("buttons.Back")}
                                            </Button>

                                            {getNextButtons()}
                                                                                        
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default TransferFunds;
