import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { AppContext, AppConsumer } from "../../providers/appProvider";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from 'react-redux';
import { actionCreators } from "../../store/NavItems";

import { createStyles, makeStyles} from "@material-ui/core/styles";

import { Link } from "react-router-dom";
import { SwipeableDrawer, Menu, MenuItem, List, ListItem, ListItemIcon, ListItemText, Paper,  Grid, Divider,
    Box, Typography, IconButton, Button, Collapse } from "@material-ui/core";

import ExpandMore from "@material-ui/icons/ExpandMore";
import RedeemIcon from '@material-ui/icons/Redeem';
import ChevronRight from "@material-ui/icons/ChevronRight";
import Icon from "@material-ui/core/Icon";
import { UserAvatar } from "../UserAvatar";
import { countryToFlag } from "../../store/countries";
import AdviserBlock from './AdviserBlock';

import i18n from "../../i18n";
const I18N_LANGUAGE = "i18nextLng"; 

const useStyles = makeStyles((theme) =>
    createStyles({
        drawer: {
            textShadow:  theme.palette.type === "dark" ? "none" : "1px 1px 2px #252525",
            background:  theme.palette.type === "dark" ? "#18202c"
                                               : "linear-gradient(338deg, rgb(54 195 225) 4%, rgb(5 84 100) 100%)",
                                          "& *": { color: "rgba(255, 255, 255, 0.9)" },
        },
        container: {
            width: theme.spacing(36)
        },
        balanceBox: {           
            backgroundColor:  theme.palette.type === "dark" ? "#00000068":  "#097e97" , 
            padding: theme.spacing(1, 1/4)            
        },
        list: {    
            width: '100%',
        },
        
        listItemIcon: {
            minWidth: 40,
        },
        
    })
);

const LeftSwipeable = (props) => {
    const { open, toggleDrawer, showDlgCertificateCode, onChangeLang } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const app = useContext(AppContext);
    
    const [ user, setUser ] = useState(app.user);

    const dispatch = useDispatch();    
    const appLayout = useSelector((state) =>  state.appLayout );
    const { Langs, Adviser, Balances, LeftNavItems } = appLayout;
    const [anchorElLang, setAnchorElLang] = useState();

    const [selectedLang, setSelectedLang] = useState(app.lang);
    
    const [ids, setIds] = useState([]);
        
    const handleLangMenuOpen = event => {
        setAnchorElLang(event.currentTarget);
    };
    
    const handleLangMenuClose = () => {
        setAnchorElLang(null);
    };
          
    const handleChangeLang = (lang) => {
        setAnchorElLang(null);                
        const URL = `/api/v1/Languege/${lang.Name}`;                                            
        fetch(URL)
          .then(() => {
            setSelectedLang(lang.TwoLetterISOLanguageName);
            app.changeLang(lang.TwoLetterISOLanguageName);            
          })        
        .catch(error => console.log(error))
        .finally(() => {          
        });        
    };
    
    const checkExpanded = (id) => {
        if(ids.length === 0) return false;
        return ids.findIndex(s => s === id) > -1;        
    };

    const handleClickListItem = (id) => {
        const list = [...ids];
                
        const idx = list.findIndex(s => s === id);
        if(idx === -1){
            list.push(id);
        }else{
            list.splice(idx, 1);
        }
        setIds(list);
    };
    
    const handleChangeTheme = () => {
        app.changeTheme();
    }
    
    useEffect(() => {        
        function fetchUser() {            
            app.UserManager.getUser().then( (user) => {                
                setUser(user);
            });            
        }
        fetchUser(); 
        setSelectedLang(app.lang);
    }, [app]);

  return (
    <AppConsumer>
        {() => (
            <>
            <SwipeableDrawer
                classes={{ paper: classes.drawer}}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}      
            >
                <div 
                    className={classes.container}
                    role="presentation"
                > 
                    {/* Login/Logout & Register & Langs */}
                    <Box p={1}>
                        <Grid container direction="row">
                            {user && 
                            <Grid item xs={9}>
                                <Button 
                                    size="small"  
                                    // onClick={handleLogout} 
                                    component={Link} to="/logout"
                                >
                                    {t("appbar.menu.Logout")}
                                </Button>
                            </Grid>
                            }
                            {!user && 
                            <Grid item xs={4}>
                                <Button 
                                    size="small"                                     
                                    component={Link} to="/signin"
                                    title="Login"
                                >
                                    {t("appbar.menu.Login")}
                                </Button>
                            </Grid>
                            }
                            {!user && 
                            <Grid item xs={5}>
                                <Button size="small" 
                                    onClick={toggleDrawer(false)}
                                    component={Link} to="/register"
                                    title="Register new user" 
                                >
                                    {t("appbar.menu.Register")}
                                </Button>
                            </Grid>
                            }

                            {Langs && 
                            <Grid item xs={3}>
                                <Button 
                                    title= {t("Change language")} 
                                    size="small" 
                                    onClick={ handleLangMenuOpen }          
                                    endIcon={<ExpandMore />}
                                >
                                    {selectedLang}
                                </Button>
                            </Grid>
                            }
                        </Grid>
                    </Box>                   
                    
                    {/* Adviser & Balance section */}
                    {Adviser &&                         
                        <Paper className={classes.balanceBox} square elevation={0}>
                            <AdviserBlock adviser={Adviser} balances={Balances} />
                        </Paper>                     
                    }

                    <List component="nav" className={classes.list} disablePadding>
                    
                    { Adviser &&
                        <ListItem button onClick={showDlgCertificateCode}>
                            <ListItemIcon className={classes.listItemIcon}><RedeemIcon /></ListItemIcon>
                            <ListItemText primary={t("common.GiftCode")}/>
                        </ListItem>
                    }                    
                    {LeftNavItems && <>
                        {
                       LeftNavItems.map((item) => {
                        return (
                          // Top level              
                           item.children.length === 0 ? (
                            <ListItem 
                                button key={item.id} 
                                component={Link} 
                                to={item.href}
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <Icon fontSize="small">{item.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItem>
                          ) : (
                              <React.Fragment key={item.id}>
                                <ListItem button key={item.id} onClick={()=> handleClickListItem(item.id)} >
                                    <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{item.icon}</Icon></ListItemIcon>
                                    <ListItemText primary={item.title} />
                                    {checkExpanded(item.id) ? <ExpandMore /> : <ChevronRight />}
                                </ListItem>
                              <Divider />
                              <Collapse in={checkExpanded(item.id)} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {item.children.map((sitem) => {
                                    return (
                                      // Second level                            
                                      sitem.children.length === 0 ? (
                                        <ListItem 
                                            key={sitem.id} 
                                            className={classes.nested} 
                                            button 
                                            target={sitem.target} 
                                            component={Link} 
                                            to={sitem.href} 
                                            onClick={toggleDrawer(false)} 
                                            onKeyDown={toggleDrawer(false)} 
                                        >
                                          <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{sitem.icon}</Icon></ListItemIcon>
                                          <ListItemText key={sitem.id} primary={sitem.title} />
                                        </ListItem> 
                                      ) : (                              
                                        <React.Fragment key={sitem.id}>
                                          <ListItem key={sitem.id} className={classes.nested} button onClick={ () => handleClickListItem(sitem.id)} >
                                            <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{sitem.icon}</Icon></ListItemIcon>
                                            <ListItemText primary={sitem.title} />
                                            {checkExpanded(sitem.id) ? <ExpandMore /> : <ChevronRight />}
                                          </ListItem>
                                          {/* <Divider /> */}
                                          <Collapse in={checkExpanded(sitem.id)} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                              {sitem.children.map((titem) => {
                                                return (
                                                  // Third level  
                                                    <ListItem 
                                                        key={titem.id} 
                                                        className={classes.nestedThird} 
                                                        button  
                                                        target={titem.target} 
                                                        component={Link} 
                                                        to={titem.href} 
                                                        onClick={toggleDrawer(false)} 
                                                        onKeyDown={toggleDrawer(false)}>
                                                        <ListItemIcon className={classes.listItemIcon}><Icon fontSize="small">{titem.icon}</Icon></ListItemIcon>
                                                        <ListItemText key={titem.id} primary={titem.title} />
                                                  </ListItem> 
                                                )})}
                                            </List>
                                          </Collapse>
                                        </React.Fragment>
                                      )                            
                                    )
                                  })}
                                </List>
                              </Collapse>
                              </React.Fragment>                  
                          )
                          
                      )})      
              
                        }
                    </>
                    }
                    </List>

                    <Divider />  
                    <List component="nav" className={classes.list} disablePadding>
                        <ListItem button onClick={handleChangeTheme}>
                            <ListItemIcon className={classes.listItemIcon} > 
                            <Icon fontSize="small">{app.theme === "light" ? "wb_sunny" : "nightlight_round"  }</Icon></ListItemIcon>
                            <ListItemText primary={`Theme: ${t(app.theme)}`}/>
                        </ListItem>        
                    </List>

                </div>
            </SwipeableDrawer>
            
            {Langs && 
            <Menu
                anchorEl={anchorElLang}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorElLang)}
                onClose={handleLangMenuClose}
            >
            {Langs.map(lang => {
                return (
                    <MenuItem
                        key={lang.Name}
                        selected={lang.TwoLetterISOLanguageName === selectedLang}
                        onClick= {() => handleChangeLang(lang)}
                    >
                    {countryToFlag(lang.Name.length === 2 ? lang.Name : lang.Name.substring(3,5) )} 
                    <span style={{marginLeft: 8, textTransform: 'capitalize'}}>
                        {lang.EnglishName}
                    </span>
                    </MenuItem>
                );
                })
            }
            </Menu>
            }

            </>
        )}
    </AppConsumer>
  )
}

LeftSwipeable.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    showDlgCertificateCode: PropTypes.func.isRequired,
    onChangeLang: PropTypes.func.isRequired
}


export default connect(
    state => state.appLayout,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(LeftSwipeable);
// export default LeftSwipeable